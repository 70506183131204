import React, { useEffect } from "react";
import useState from "react-usestateref";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from 'moment-timezone';
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

export default function Deposit() {
  const [depositHistory, setdepositHistory] = useState([]);
  useEffect(() => {
    getdepositHistory();
  }, [0]);

  const [deposittotalpage, setdepositTotalpages] = useState(0);
  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const depositrecordpage = 5;

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
      setdepositTotalpages(deposit_history_list.total);
    }
  };

  const handlepagedeposit = (event, page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="deposit_main">
        <div className="container">
          <div className="dashboard_table_content mt-5">
            <div className="deposit_history_content">
              <h3 className="referral_title">Deposit History</h3>
              <p>View all</p>
            </div>
            <div className="table">
              <table class="wxdl_dashboard table">
                <thead>
                  <tr>
                    <th scope="col" className="start">
                      SI.no
                    </th>
                    <th scope="col">Currency</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date & time</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col" className="end">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {depositHistory && depositHistory.length > 0 ? (
                    depositHistory.map((item, i) => {
                      return (
                        <tr>
                          <th scope="row">{i + 1}</th>
                          <td className="usdt_currency_items">
                            <img src={item.Currency_image} width="25px" />
                            <p>{item.currencySymbol}</p>
                          </td>
                          <td>
                            {parseFloat(item.amount).toFixed(4)}{" "}
                            {item.currencySymbol}{" "}
                          </td>
                          <td>
                          { convertUTCToIST(item.date) }
                          </td>
                          <td>
                            {item.txnid != undefined
                              ? item.txnid.slice(0, 10)
                              : "-"}{" "}
                            {""}{" "}
                            <i
                              class="ri-file-copy-line"
                              onClick={() => copy(item.txnid)}
                              style={{
                                cursor: "pointer",
                              }}
                            ></i>
                          </td>
                          <td className="text-green">Success</td>
                        </tr>
                      );
                    })
                  ) : (
                    <td colSpan={6} className="background_trans">
                      <div className="empty_data">
                        <div className="empty_data_img">
                          {/* <img
                          src={require("../images/No-data.png")}
                          width="100px"
                        /> */}
                        </div>

                        <div className="no_records_text">No Records Found</div>
                      </div>
                    </td>
                  )}
                </tbody>
              </table>
            </div>

            {depositHistory && depositHistory.length > 0 ? (
              <div className="pagination">
                <Pagination
                  count={Math.ceil(deposittotalpage / depositrecordpage)}
                  page={depositcurrentpage}
                  onChange={handlepagedeposit}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
