import React, { useEffect } from "react";
import useState from "react-usestateref";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

import "semantic-ui-css/semantic.min.css";

export default function Deposit() {
  const [buttonLoader, setbuttonLoader] = useState(false);


  const initialFormValue = {
    internalID: "",
    address: "",
    password: ""
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [addressValidate, setaddressValidate] = useState(false);

  const [passwordValidate, setpasswordValidate] = useState(false);

  const [validationnErr, setvalidationnErr] = useState("");

  const { internalID, address, password } = formValue;

  useEffect(() => {
    getProfile();
  }, [0]);



  const getProfile = async () => {
    try {
      var datas = {
        apiUrl: apiService.getuserdetail,
      };
      var responce = await getMethod(datas);
      if (responce != null) {
        if(responce.status)
        {
          formValue.internalID = responce.data.internalID;
        }
        
      }
    } catch (error) {
      console.log(error, "error");
      // toast.success("Please try  later!");
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
      let formData = { ...formValue, ...{ [name]: sanitizedValue } };
      setFormValue(formData);
      validate_preview(formData);
  };

  const validate_preview = async (values) => {
    const errors = {
      address: "",
      password: ""
    };

    if (!values.address) {
      errors.address = "Address is a required field";
      setaddressValidate(true);
    }
    else {
      errors.address = "";
      setaddressValidate(false);
    }

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (values.password.length < 5 || values.password.length > 21) {
      errors.password = "Password must be between 5 and 21 characters";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const [transctionHistory, settransctionHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 5;


  useEffect(() => {
    geHistory(currentPage);
  }, [currentPage]);



  const geHistory = async (page) => {
    try {
      const data = {
        apiUrl: apiService.withdrawaddress_history,
        payload: { FilPerpage: recordsPerPage, FilPage: page },
      };
      const history_list = await postMethod(data);

      if (history_list && history_list.status) {
        settransctionHistory(history_list.result);
        setTotalPages(Math.ceil(history_list.pages / recordsPerPage));
      } else {
        settransctionHistory([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const transactionSubmit = async () => {
    try {
      validate_preview(formValue);
      if (
        formValue.internalID != "" &&
        formValue.address != "" &&
        formValue.password != ""
      ) {
            var obj = {
            address: formValue.address,
            password: formValue.password
            };
            
            var data = {
            apiUrl: apiService.submitAddress,
            payload: obj,
            };
            setbuttonLoader(true);
            var resp = await postMethod(data);
            if (resp.status) {
            showsuccessToast(resp.message);
            geHistory(1);
            setbuttonLoader(false);
            formValue.address = "";
            formValue.password = "";
            } else {
            showerrorToast(resp.message);
            setbuttonLoader(false);
            }
        
      }
      else
      {

      }
    } catch (error) { }
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="deposit_main">
        <div className="container">
          <div className="">
            <p class="titlr_lable_dd text-start pt-5">
              <a href="/" class="button_home">
                <span>Home</span>
              </a>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <a href="/withdraw" class="button_home">
              <small>Withdraw</small>
              </a>

              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <small>Withdraw Address Update</small>
            </p>
          </div>
          <div className="p2p_table_content">
            <div className="row">
              <div className="col-lg-5 wxdl_deposit">
                     

                    <div className="wxdl_deposit_content">
                      <h3>WXDL UserID</h3>
                      <input
                        name="internalID"
                        type="text"
                        value={internalID}
                        onChange={handleChange}
                        placeholder="Enter Your WXDL UserID"
                        className="withdraw_input_address"
                        disabled
                      />
                    </div>

                    <div className="wxdl_deposit_content">
                      <h3>Withdraw Address</h3>
                      <input
                        name="address"
                        type="text"
                        value={address}
                        onChange={handleChange}
                        placeholder="Enter Your Withdraw Address"
                        className="withdraw_input_address"
                      />
                    </div>
                    {addressValidate == true ? (
                      <p className="errorcss"> {validationnErr.address} </p>
                    ) : (
                      ""
                    )}

                    <h3 className="witthdraw_amt_title">
                      Transaction Password
                    </h3>
                    <div className="currency_copy_content">
                      <input
                        type={inputType}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        placeholder="Enter Your Password"
                      />
                      {passHide == true ? (
                        <i
                          className="fa-regular fa-eye reg_eye"
                          onClick={() => passwordHide("show")}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye-slash reg_eye"
                          onClick={() => passwordHide("hide")}
                        ></i>
                      )}
                    </div>
                    {passwordValidate && (
                      <p className="errorcss">{validationnErr.password}</p>
                    )}
                <div className="remainder_content">

                </div>
                <div className="withdrawal_button">
                  {buttonLoader === true ? (
                      <button >Loading</button>
                    ) : (
                      <button onClick={() => transactionSubmit()}>Submit</button>
                    )}
                </div>
              </div>
            </div>
            <div className="dashboard_table_content mt-5">
              <div className="deposit_history_content">
                <h3 className="referral_title"> History</h3>
                {/* <p>View all</p> */}
              </div>
              <div className="table_overflow">
                <table class="wxdl_dashboard table ">
                  <thead>
                    <tr>
                      <th scope="col" className="start">
                        SI.No
                      </th>
                      <th scope="col">Withdraw Address</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col" className="end">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transctionHistory && transctionHistory.length > 0 ? (
                      transctionHistory.map((item, i) => {
                        return (
                          <tr>
                            <th scope="row"> {i + 1} </th>
                            <td>
                              {item.address}{" "}
                              <i
                                class="ri-file-copy-line"
                                onClick={() => copy(item.address)}
                              ></i>
                            </td>
                            <td>{convertUTCToIST(item.date)}</td>
                            
                            {item.status == "Pending" ? (
                              <td className="text-danger"> {item.status} </td>
                            ) : (
                              <td className="text-green"> {item.status} </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4"> No Data found! </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  className="table_pagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
