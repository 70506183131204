import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

function Dashboard() {
  const [loaderButton, setloaderButton] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoadertfa, setsiteLoadertfa] = useState(false);

  useEffect(() => {
    fetchTfaData();
  }, [])



  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      setsiteLoadertfa(true);
      var resp = await getMethod(data);
      setsiteLoadertfa(false);
      setTfaDetails(resp.data.tfastatus);
      setaddDetails(resp.data);
    } catch (error) { }
  };

  const handletfa = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      toast("2FA code is required");
    } else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
       showsuccessToast(resp.Message);
        setTfaCode("");
        fetchTfaData();
        localStorage.setItem("tfa_status", resp.result.tfastatus);
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
       showerrorToast(resp.Message);
      }
    }
  };
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
   showsuccessToast("Secret Key Copied");
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <div>
    <Header />
    <div className="register-one">
      <div className="container">
        <div className="row register-form justify-content-center reg_row">
          <div className="col-lg-5 mt-2">
            <div className="reg_right_main">
              <div className="reg_left_head">2FA</div>
              <div className="p">Link Google Authenticator</div>
  
              <div className="reg_in_main">
                <div className="reg_in_top">
                  <div className="reg_infields">
                    {tfaDetails === 0 ? (
                      <>
                        <img src={addDetails.tfa_url} className="qrcodes" />
  
                        <div className="req_field_sin">
                          <h5 className="earn_divs_insubhead">Secret Key</h5>
                          <div className="flex_input_posion">
                            <input
                              type="text"
                              value={
                                addDetails.tfaenablekey == undefined
                                  ? ""
                                  : addDetails.tfaenablekey
                              }
                              readOnly
                              className="reg_inp_field"
                            />
  
                            <i
                              class="fa-regular fa-copy search-icon text-white"
                              id="Eye"
                              onClick={() => copy(addDetails.tfaenablekey)}
                            ></i>
                          </div>
                        </div>
                      </>
                    ) : (
                      " "
                    )}
  
                    <div className="req_field_sin">
                      <h5 className="earn_divs_insubhead">2FA Code</h5>
                      <input
                        className="reg_inp_field"
                        type="number"
                        min="0"
                        placeholder="Enter Your Code"
                        name="tfaCode"
                        value={tfaCode}
                        onChange={(e) => setTfaCode(e.target.value)}
                      />
                    </div>
  
                    <div className="">
                      <div className="template-btn btn-style-one d-flex justify-content-center mt-4">
                        {tfaDetails === 0 ? (
                          loaderButton == false ? (
                            <span className="btn-wrap" onClick={handletfa}>
                              <span className="text-one">Enable</span>
                              <span className="text-two">Enable</span>
                            </span>
                          ) : (
                            <span className="btn-wrap">
                              <span className="text-one">Loading</span>
                              <span className="text-two">Loading</span>
                            </span>
                          )
                        ) : loaderButton == false ? (
                          <span className="btn-wrap" onClick={handletfa}>
                            <span className="text-one">Disable</span>
                            <span className="text-two">Disable</span>
                          </span>
                        ) : (
                          <span className="btn-wrap">
                            <span className="text-one">Loading</span>
                            <span className="text-two">Loading</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Dashboard;
