import React from "react";

const TreeNode = ({ nodeData, onNodeClick, depth }) => {

  const maxDepth = 3;

  if (!nodeData) {
    return (
      <li className="empty-node">
        <div>
          <img src={require("../assets/images/dash_icon/empty.png")} alt="Empty" />
        </div>
      </li>
    );
  }

  return (
    <li className="tree-node" onClick={(event) => onNodeClick(nodeData.internalID, event)}>
      <div className="tree_view_profile_icon relative">
        <img
          src={nodeData.stakeamount === 0
            ? require("../assets/images/dash_icon/yellow.png")
            : require("../assets/images/dash_icon/green.png")}
          alt="Profile"
        />
        <p className="m-0">{nodeData.username}</p>
        <p>{nodeData.internalID}</p>

      </div>
      {depth < maxDepth && (nodeData.leftUser || nodeData.rightUser) && (
        <ul className="children">
          <TreeNode nodeData={nodeData.leftUser} onNodeClick={onNodeClick} depth={depth + 1} />
          <TreeNode nodeData={nodeData.rightUser} onNodeClick={onNodeClick} depth={depth + 1} />
        </ul>
      )}
    </li>
  );
};


export default TreeNode;
