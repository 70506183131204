import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import InnerHeader from "./Header";

const ForgotPassword = () => {
  const initialFormValue = {
    otp: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationErr, setValidationErr] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);
  const [passHide, setPassHide] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [inputTypeConf, setInputTypeConf] = useState("password");

  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};
    if (!values.otp) {
      errors.otp = "OTP is a required field!";
    } else if (values.otp.length !== 4) {
      errors.otp = "OTP must be 4 digits!";
    }

    if (!values.newPassword) {
      errors.newPassword = "New Password is a required field!";
    } else if (values.newPassword.length < 5 || values.newPassword.length > 25) {
      errors.newPassword = "Password should be between 5 and 25 characters!";
    } else if (!values.newPassword.match(/[a-z]/g)) {
      errors.newPassword = "Password must contain at least one lowercase letter!";
    } else if (!values.newPassword.match(/[A-Z]/g)) {
      errors.newPassword = "Password must contain at least one uppercase letter!";
    } else if (!values.newPassword.match(/[0-9]/g)) {
      errors.newPassword = "Password must contain at least one digit!";
    } else if (!values.newPassword.match(/[!@#$%^&*]/g)) {
      errors.newPassword = "Password must contain at least one special character!";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is a required field!";
    } else if (values.confirmPassword !== values.newPassword) {
      errors.confirmPassword = "Passwords do not match!";
    }

    setValidationErr(errors);
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    validate({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValue);
    if (Object.keys(errors).length === 0) {
      setButtonLoader(true);
      const obj = {
        otp: formValue.otp,
        password: formValue.newPassword,
        confimPassword: formValue.confirmPassword,

      };
      try {
        // const response = await postMethod({ url: apiService.forgottransactionpassword, data });
        var data = {
          apiUrl: apiService.forgottransactionpassword,
          payload: obj,
        };
        var response = await postMethod(data);

        setButtonLoader(false);
        if (response.status == true) {
          toast.success(response.Message);
          navigate("/dashboard");
        } else {
          toast.error(response.Message);
        }
      } catch (error) {
        setButtonLoader(false);
        toast.error("Something went wrong, please try again later.");
      }
    }
  };

  const handlePassHide = () => {
    setPassHide(!passHide);
    setInputType(passHide ? "password" : "text");
  };

  const handlePassHideConf = () => {
    setInputTypeConf(inputTypeConf === "password" ? "text" : "password");
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="settings_main">
        <div className="container padding_inner">
          <div className="">
            <p className="titlr_lable_dd text-start">
              <a href="/" className="button_home">
                <span>Home</span>
              </a>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <a href="/settings" className="button_home">
                <span>Settings</span>
              </a>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <small>Forget password</small>
            </p>
          </div>
          <section className="forgot_password_section">
            <div className="row">
              <div className="col-lg-5 settings_password_tabs">
                <div className="change_password_content">
                  <h2>Forgot Password</h2>
                  <p>We Have Sent an OTP to the Registered Email Address</p>
                  <form>
                    <h3>OTP</h3>
                    <input
                      type="text"
                      name="otp"
                      placeholder="Enter Your OTP"
                      value={formValue.otp}
                      onChange={handleChange}
                    />
                    {validationErr.otp && <p className="errorcss">{validationErr.otp}</p>}


                    <div>
                      <h3>New Password</h3>
                      <div className="flex_input_posion">
                        <input
                          type={inputType}
                          name="newPassword"
                          placeholder="Enter Your Confirm Password"
                          value={formValue.newPassword}
                          onChange={handleChange}
                          className="reg_inp_field"
                        />

                        <span
                          className="pass-hide-icon"
                          onClick={handlePassHide}
                        >
                          {passHide ? <i class="fa-regular icons fa-eye reg_eye" /> : <i class="fa-regular icons fa-eye-slash reg_eye" />}
                        </span>
                      </div>

                      {validationErr.newPassword && (
                        <span className="errorcss">
                          {validationErr.newPassword}
                        </span>
                      )}
                    </div>

                    <div>
                      <h3>Confirm Password</h3>
                      <div className="flex_input_posion">
                        <input
                          type={inputTypeConf}
                          name="confirmPassword"
                          placeholder="Re-Enter Your New Password"
                          value={formValue.confirmPassword}
                          onChange={handleChange}
                          className="reg_inp_field"
                        />

                        <span
                          className="pass-hide-icon"
                          onClick={handlePassHideConf}
                        >
                          {inputTypeConf ? <i class="fa-regular icons fa-eye reg_eye" /> : <i class="fa-regular icons fa-eye-slash reg_eye" />}
                        </span>
                      </div>

                      {validationErr.confirmPassword && (
                        <span className="errorcss">
                          {validationErr.confirmPassword}
                        </span>
                      )}
                    </div>
                    {buttonLoader ? (
                      <button className="password_sumbit_button" type="submit" >Submitting</button>
                    ) : (
                      <button onClick={handleSubmit} className="password_sumbit_button" type="submit" >Submit</button>

                    )
                    }
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
