import React, { useEffect } from "react";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import InnerHeader from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import moment from 'moment-timezone';

const Dashboard = () => {
  const navigate = useNavigate();
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }

    swaphistory();
  }, [0]);

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setTotalpages(resp.data.pages);
      }
    } catch (error) {}
  };

  const handlePageChange = (pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  return (
    <div className="dashboard_main">
      <div className="inner_header">
        <InnerHeader />
      </div>
      <section className="dashboard_content_section">
        <div className="container">
          <div className="dashboard_table_content">
            <h3 className="referral_title">Swap History</h3>
            <div className="table_overflow">
              <table class="wxdl_dashboard table">
                <thead>
                  <tr>
                    <th scope="col" className="start">
                      S.No
                    </th>
                    <th>Date/Time</th>
                    <th>From-Currency</th>
                    <th>To-Currency</th>
                    <th>Amount</th>
                    <th>Price</th>
                    <th scope="col" className="end">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sessionHistory && sessionHistory.length > 0 ? (
                    sessionHistory.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1} </td>
                          <td>{convertUTCToIST(item.createdDate)}</td>
                          <td>{item.fromCurrency}</td>
                          <td>{item.toCurrency}</td>
                          <td>
                            {parseFloat(item.amount).toFixed(4)} {""}{" "}
                            {item.fromCurrency}
                          </td>
                          <td>
                            {" "}
                            {parseFloat(item.amount).toFixed(4)} {""}{" "}
                            {item.toCurrency}
                          </td>
                          <td className="text-green"> Completed </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7"> No Data found! </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Pagination
                  count={Math.ceil(totalPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  className="table_pagination"
                  shape="rounded"
                />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
