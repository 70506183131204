import React, { useEffect } from "react";
import InnerHeader from "./Header";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";

const TeamA = () => {
    const [packageData, setPackageData] = useState([]); // State for storing package data
    const [Active, setActive] = useState(
        {}
    ); // State for storing package data


    const [loading, setLoading] = useState(true);

    const [totalstake, settotalstake, totalstakeref] = useState(0);


    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const usersPerPage = 10; // Users to display per page

    useEffect(() => {
        getReferralUser();
    }, []);

    const getReferralUser = async () => {
        try {
            setLoading(true);
            const data = {
                apiUrl: apiService.dailystaking,
            };
            const resp = await getMethod(data);
            if (resp) {

                setActive(resp.data.leftactive);

                // Default packages array
                const packages = [
                    50, 100, 200, 300, 500, 1000, 2000, 5000, 8000, 10000
                ];

                // Initialize members and totals for each package to 0
                const packageDataArray = packages.map(pkg => ({ package: pkg, members: 0, total: 0 }));
                let totalStake = 0;
                // Update packageData based on the JSON response
                resp.data.leftSideStakedAmount.forEach(item => {
                    const stakeAmount = item.StakeAmount;
                    totalStake += stakeAmount;

                    console.log(stakeAmount, "stakeAmount");


                    const packageIndex = packageDataArray.findIndex(pkg => pkg.package === stakeAmount);

                    if (packageIndex !== -1) {
                        packageDataArray[packageIndex].members += 1;
                        packageDataArray[packageIndex].total += stakeAmount;
                    }
                });
                settotalstake(totalStake);

                setPackageData(packageDataArray); // Update state with package data
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching tree data", error);
            setLoading(false);
        }
    };

    // Handle page change for pagination
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Logic for displaying current packages based on pagination
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentPackages = packageData.slice(indexOfFirstUser, indexOfLastUser);

    return (
        <>
            <div className="inner_header">
                <InnerHeader />
            </div>

            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div className="preloader-close">x</div>
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="txt-loading">
                                    <span data-text-preloader="L" className="letters-loading">L</span>
                                    <span data-text-preloader="O" className="letters-loading">O</span>
                                    <span data-text-preloader="A" className="letters-loading">A</span>
                                    <span data-text-preloader="D" className="letters-loading">D</span>
                                    <span data-text-preloader="I" className="letters-loading">I</span>
                                    <span data-text-preloader="N" className="letters-loading">N</span>
                                    <span data-text-preloader="G" className="letters-loading">G</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="swap_main p2p_table_content">
                    <div className="dashboard_table_content container mt-5">
                        <div className="deposit_history_content">
                            <h3 className="referral_title"> Today Business Strategy</h3>
                        </div>
                        <div className="deposit_history_content">
                            <h3 className="referral_title">Team A : Active {Active.otherCount} / Inactive {Active.zeroOrLessCount}</h3>
                        </div>
                        <div className="table_overflow" otherCount>
                            <table className="wxdl_dashboard three table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="start">Package</th>
                                        <th scope="col">Members</th>
                                        <th scope="col" className="end">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPackages.map((pkg, index) => (
                                        <tr key={index}>
                                            <td>{pkg.package}</td>
                                            <td>{pkg.members}</td>
                                            <td>{pkg.total}</td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td >Total</td>
                                        <td >:</td>

                                        <td>{totalstakeref.current}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TeamA;
