import Header from "./Header";
import { toast } from "react-toastify";

import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { setAuthorization } from "../core/service/axios";

function Landing() {
  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { tfa } = formValue;
  const { state } = useLocation();

  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is Required";
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.verify_otp,
        payload: {
          tfa_code: tfa,
          socketToken: state?.socketToken,
        },
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      console.log("tfa login===", resp);
      if (resp.status) {
        toast.success(resp.Message);
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("tfa_status", 1);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="register-one">
        <div className="container">
          <div className="row register-form justify-content-center reg_row">
            <div className="col-lg-5 mt-2">
              <div className="reg_right_main">
                <div className="reg_left_head">2FA</div>
                <div className="p">verify with authenticator code</div>

                <div className="reg_in_main">
                  <div className="reg_in_top">
                    <div className="reg_infields">
                      <div className="req_field_sin">
                        {/* <h5 className="earn_divs_insubhead">Password</h5> */}
                        <div className="flex_input_posion">
                          <input
                            className="reg_inp_field"
                            type="number"
                            placeholder="Enter Your 2FA Code "
                            min={100000}
                            name="tfa"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            value={tfa}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 6) {
                                setFormValue({ tfa: value });
                              }
                            }}
                          />
                        </div>
                        {tfaValidate == true ? (
                          <p className="errorcss">{validationnErr.tfa}</p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="">
                        {!buttonLoader ? (
                          <div
                            onClick={formSubmit}
                            className="template-btn btn-style-one d-flex justify-content-center mt-4"
                          >
                            <span className="btn-wrap">
                              <span className="text-one">Submit</span>
                            </span>
                          </div>
                        ) : (
                          <div className="template-btn btn-style-one d-flex justify-content-center mt-4">
                            <span className="btn-wrap">
                              <span className="text-one">Loading</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
}

export default Landing;
