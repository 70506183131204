import React, { useEffect } from "react";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getMethod,postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import InnerHeader from "./Header";
import "semantic-ui-css/semantic.min.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

export default function Deposit() {
  const initialFormValue = {
    userID: "",
    amount: "",
    password: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationErr, setValidationErr] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [p2pResponse, setp2pResponse] = useState("");

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };
  const validate = (values) => {
    const errors = {};

    // User ID validation
    if (!values.userID) {
      errors.userID = "User ID is a required field!";
      // } else if (!/^\d{1,8}$/.test(values.userID)) {
      //   errors.userID = "User ID must be a maximum of 8 digits and only numeric.";
    }

    // Amount validation
    if (!values.amount) {
      errors.amount = "Amount is a required field!";
    }

    // Password validation
    if (!values.password) {
      errors.password = "Password is a required field!";
    }

    setValidationErr(errors);
    return errors;
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const [depositHistory, setDepositHistory] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [Username, setUsername] = useState("");
  const [p2p_balance,setp2pBalance] = useState(0);

  const pageSize = 5;
  const navigate = useNavigate();

  const fetchDepositHistory = async () => {
    try {
      const response = await postMethod({
        apiUrl: apiService.p2p_history,
        payload: {
          currentPage: currentPage,
          pageSize: pageSize,
        },
      });

      if (response.success) {
        setDepositHistory(response.data.data);
        setTotalPages(response.data.pages);
      } else {
        setDepositHistory([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error("Error fetching deposit history:", error);
      toast.error("Failed to fetch data.");
    }
  };
  const fetchp2pBalance = async () => {
    try {
      const response = await getMethod({
        apiUrl: apiService.p2p_balance
      });

      console.log("p2p balance===",response)

      if (response.success) {
        setp2pBalance(response.data);
      } else {
        setp2pBalance(0);
      }
    } catch (error) {
      console.error("Error fetching deposit history:", error);
      setp2pBalance(0);
    }
  };

  useEffect(() => {
    fetchDepositHistory();
    fetchp2pBalance();
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Trim whitespace from the value
    let trimmedValue = value.trim();

    if (name == "amount") {
      if (trimmedValue.length < 8) {
        setFormValue({ ...formValue, [name]: trimmedValue });
        validate({ ...formValue, [name]: trimmedValue });
      }
    } else if (name === "userID") {
      setFormValue({ ...formValue, [name]: trimmedValue });
      validate({ ...formValue, [name]: trimmedValue });

      var data = {
        apiUrl: apiService.finduser,
        payload: { userID: trimmedValue },
      };

      var response = await postMethod(data);

      if (response.success) {
        setUsername(response.username);
      } else {
        setUsername("User not found");

      }
    } else {
      setFormValue({ ...formValue, [name]: trimmedValue });
      validate({ ...formValue, [name]: trimmedValue });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValue);
    if (Object.keys(errors).length === 0) {

      if (Username == "User not found") {
        toast.error("User Not Found");
      } else {
      setButtonLoader(true);
      
        const obj = {
          from: formValue.userID,
          amount: formValue.amount,
          currency: "WXDL",
          password: formValue.password,
          Username: Username
        };
        if(+formValue.amount > 0)
        {
          if(+p2p_balance < formValue.amount)
            {
              toast.error("Insufficient Balance");
              setButtonLoader(false);
            }
            else
            {
              try {
                var data = {
                  apiUrl: apiService.internaltransfer,
                  payload: obj,
                };
                var response = await postMethod(data);
                setButtonLoader(false);
                setFormValue(initialFormValue);
                setUsername("");
                if (response.status === true) {
                  // toast.success(response.Message);
                  setp2pResponse(obj);
                  setModalVisible(true);
                  fetchDepositHistory();
                } else {
                  toast.error(response.Message);
                }
              } catch (error) {
                setButtonLoader(false);
                toast.error("Something went wrong, please try again later.");
              }
            }
        }
        else{
          
          toast.error("Please enter valid amount!");
          setButtonLoader(false);
        }
        
       
      }

     
    }
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  return (
    <div className="deposit_main">
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="container">
        <div className="p2p_table_content">
          <div className="row">
            <div class="">
              <p class="titlr_lable_dd text-start">
                <a href="/" class="button_home">
                  <span>Home</span>
                </a>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                <small>P2P</small>
              </p>
            </div>
            <div className="col-lg-5 wxdl_deposit">
              <div className="wxdl_deposit_content">
                <form onSubmit={handleSubmit}>
                  <div className="d-flex justify-content-between align-items-center">
                  <h3 className="witthdraw_amt_title">User ID</h3>
                  
                  </div>
                  <div className="currency_copy_content">
                    <input
                      type="text"
                      name="userID"
                      max="8"
                      maxLength="8"
                      placeholder="Enter Your ID"
                      value={formValue.userID}
                      onChange={handleChange}
                    />
                    
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                  {validationErr.userID ? (
                    <p className="errorcss mt-2">{validationErr.userID}</p>
                  ) : <p></p>}
                  {Username == "User not found" ? (
                    <h3 className="witthdraw_amt_title text-danger">{Username}</h3>

                  ) : (
                    <h3 className="witthdraw_amt_title text-success">{Username}</h3>

                  )}
                  </div>

                 

                  <h3 className="witthdraw_amt_title">Amount</h3>
                  <div className="currency_copy_content">
                    <input
                      type="number"
                      name="amount"
                      placeholder="Enter The Amount"
                      value={formValue.amount}
                      onChange={handleChange}
                      pattern="[0-9]*"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                    />
                    WXDL
                  </div>
                  {validationErr.amount && (
                    <p className="errorcss mt-2">{validationErr.amount}</p>
                  )}

                  <h3 className="witthdraw_amt_title">Transaction Password</h3>
                  <div className="currency_copy_content">
                    <input
                      type={inputType}
                      name="password"
                      placeholder="Enter  Transaction Password"
                      value={formValue.password}
                      maxLength="36"
                      onChange={handleChange}
                    />
                    {passHide == true ? (
                      <i
                        className="fa-regular fa-eye reg_eye"
                        onClick={() => passwordHide("show")}
                      ></i>
                    ) : (
                      <i
                        className="fa-regular fa-eye-slash reg_eye"
                        onClick={() => passwordHide("hide")}
                      ></i>
                    )}
                  </div>
                  {validationErr.password && (
                    <p className="errorcss mt-2">{validationErr.password}</p>
                  )}

                  <div className="remainder_content">
                    <h3>Reminder</h3>
                    <div className="min_dep_text">
                    <p>Available Balance</p>
                    <p>
                      {p2p_balance} WXDL
                    </p>
                  </div>
                  </div>
                  <div className="withdrawal_button ">
                    {buttonLoader ? (
                      <button
                        className="password_sumbit_button"
                        type="submit"
                        disabled
                      >
                        Submitting...
                      </button>
                    ) : (
                      <button className="password_sumbit_button" type="submit">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="dashboard_table_content mt-5">
            <div className="deposit_history_content">
              <h3 className="referral_title">P2P History</h3>
            </div>
            <div className="table_overflow">
              <table className="wxdl_dashboard table">
                <thead>
                  <tr>
                    <th scope="col" className="start">
                      SI.No
                    </th>
                    <th scope="col">Username</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Fees</th>
                    <th scope="col" className="end">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {depositHistory.length > 0 ? (
                    depositHistory.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.from}</td>
                        <td>{parseFloat(item.amount).toFixed(4)}</td>
                        <td>
                          { convertUTCToIST(item.createdDate) }
                        </td>
                        <td>{item.fees.toFixed(2)}</td>
                        {item.type == "Received" ? (
                          <td className="text-green">{item.type}</td>
                        ) : (
                          <td className="text-red">{item.type}</td>
                        )}
                        
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="background_trans">
                        <div className="empty_data">
                          <div className="no_records_text">
                            No Records Found
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {totalPages > 1 && (
              <div className="pagination">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  className="table_pagination"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </div>
            )}

           <Modal isOpen={modalVisible} className="modalss" toggle={() => setModalVisible(false)}>
              <ModalBody>
                <div className="modal-header kyc_modal_header">
                  <img
                    src={require('../assets/images/dash_icon/tick.webp')}
                    className="d-block mx-auto"
                    width="100px"
                    alt="Success"
                  />
                  <h6>
                    Transaction successfully done. <br />
                     <span>{p2pResponse.amount} WXDL</span> was sent to <span> {p2pResponse.Username} ({p2pResponse.from}) </span>
                  </h6>
                  <h4>
                    <button onClick={handleClose}>
                      Close
                    </button>
                  </h4>
                </div>
              </ModalBody>
            </Modal>

          </div>
        </div>
      </div>
    </div>
  );
}
