import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { postMethod } from "../core/service/common.api";
import moment from "moment-timezone";

const Swap = () => {
  const [depositHistory, setDepositHistory] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const fetchDepositHistory = async () => {
    try {
      const response = await postMethod({
        apiUrl: apiService.binaryprofit_history,
        payload: {
          currentPage: currentPage,
          pageSize: pageSize,
        },
      });

      if (response.success) {
        setDepositHistory(response.data.data);
        setTotalPages(response.data.pages);
      } else {
        setDepositHistory([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error("Error fetching deposit history:", error);
      toast.error("Failed to fetch data.");
    }
  };

  useEffect(() => {
    fetchDepositHistory();
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const istDate = new Date(utcDateString).toLocaleString("en-IN", options);

    return istDate;
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="swap_main p2p_table_content">
        <div className="historytabs container mt-5 mb-4">
          <div className="d-flex">
            <Link to="/profithistory">Daily Income</Link>

            <Link className="active" to="/binaryhistory">
              Binary Income
            </Link>

            <Link to="/directhistory">Direct Income</Link>
          </div>
        </div>
        <div className="dashboard_table_content container mt-3">
          <div className="deposit_history_content">
            <h3 className="referral_title">Profit History</h3>
          </div>
          <div className="table_overflow">
            <table className="wxdl_dashboard three table">
              <thead>
                <tr>
                  <th scope="col" className="start">
                    SI.No
                  </th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {depositHistory.length > 0 ? (
                  depositHistory.map((item, i) => (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>
                        {item.type == "directIncome"
                          ? parseFloat(item.directIncome).toFixed(4)
                          : item.type == "dailyIncome"
                            ? parseFloat(item.dailyIncome).toFixed(4)
                            : parseFloat(item.BinaryIncome).toFixed(4)}
                      </td>
                      <td>{convertUTCToIST(item.Date)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="background_trans">
                      <div className="empty_data">
                        <div className="no_records_text">No Records Found</div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {totalPages > 0 && (
            <div className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                className="table_pagination"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Swap;
