import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import InnerHeader from "./Header";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Pagination from "@mui/material/Pagination";

import PaginationItem from "@mui/material/PaginationItem";
import useStateRef from "react-usestateref";
import moment from 'moment-timezone';
const Swap = () => {
  const navigate = useNavigate();
  const depositrecordpage = 5;
  const [siteLoader, setSiteLoader] = useState(false);
  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromCurrency, setfromCurrency, fromref] = useState("");
  const [toCurrency, settoCurrency, toref] = useState("USDT");
  const [appendFromData, setappendFromData] = useState("");
  const [appendToData, setappendFToData] = useState("");
  const [fromcurrencyImage, setFromcurrencyImage] = useState("");
  const [tocurrencyImage, setTocurrencyImage] = useState("");
  const [fromAmount, setfromAmount] = useState(0);
  const [toAmount, settoAmount, toAmountref] = useStateRef(0);
  const [minMax, setMinMax] = useState(10);
  const [price, setPrice, priceRef] = useStateRef(0);
  const [estimateFee, setEstimationFee] = useState(0);
  const [totatlAmount, setTotalAmount] = useState(0);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [depositHistory, setdepositHistory] = useState([]);
  const [deposittotalpage, setdepositTotalpages] = useState(0);
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);
  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };
  const [fromSwap, setfromSwapRef, fromSwapRef] = useState([]);
  const [toSwap, settoSwapRef, toSwapRef] = useState([]);

  useEffect(() => {
    getUserbalance();
    swaphistory();
  }, [0]);

  const getUserbalance = async () => {
    var data1 = {
      apiUrl: apiService.getUserBalanceSwap,
    };
    var resp = await getMethod(data1);
    console.log(resp, "resp");
    if (resp.status) {
      setFromTab(resp.data);
      setToTab(resp.data);
      var currArrayCrypto = [];
      //var currArrayFiat = [{value: "all", label: "Select"}];
      var currArrayFiat = [];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].currencySymbol) {
          var obj = {
            value: data[i].currid,
            label: data[i].currencySymbol,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayFiat.push(obj);
          currArrayCrypto.push(obj);
        }
      }
      setToCurrencyRef(currArrayFiat);
      setfromCurrencyRef(currArrayCrypto);
    } else {
    }
  };

  const setAmount = async (value, type) => {
    console.log("value", value, "type", type);
    console.log(appendFromData, "appendFromData");
    try {
      if (!isNaN(value)) {
        type == "fromAmount" ? setfromAmount(value) : settoAmount(value);

        if (fromCurrency != "" && toCurrency != "") {
          var total = +value;
          console.log("total===", total);
          //setTotalAmount(parseFloat(total).toFixed(8));
          setTotalAmount(total);

          setPrice(1);
          console.log("price===", 1);
          if (type == "fromAmount") {
            var amount = Number(1) * Number(value);
            console.log("amount===", amount);
            // setfromAmount(parseFloat(value).toFixed(8));
            // settoAmount(parseFloat(amount).toFixed(8));
            setfromAmount(value);
            settoAmount(amount);
          } else if (type == "toAmount") {
            var amount = Number(value) / Number(1);
            // setfromAmount(parseFloat(amount).toFixed(8));
            // settoAmount(parseFloat(value).toFixed(8));
            setfromAmount(amount);
            settoAmount(value);
          }
        } else {
        }
      } else {
        type == "fromAmount" ? setfromAmount(0) : settoAmount(0);
      }
    } catch (error) { }
  };

  const swapAmount = async () => {
    console.log(appendFromData.currencySymbol, appendToData.currencySymbol);
    try {
      if (
        appendFromData.currencySymbol != undefined &&
        appendToData.currencySymbol != undefined
      ) {
        if (appendFromData.currencySymbol != appendToData.currencySymbol) {
          if (fromAmount > 0 && toAmount > 0) {
            var obj = {
              from: appendFromData.currencySymbol,
              to: appendToData.currencySymbol,
              // from_id: appendFromData.currid,
              // to_id: appendToData.currid,
              fromAmount: +fromAmount,
              toAmount: +toAmount,
              // currentPrice: +priceRef.current,
            };

            // console.log(obj, "obj");

            var data = {
              apiUrl: apiService.swapping,
              payload: obj,
            };
            setButtonLoader(true);
            var resp = await postMethod(data);
            setButtonLoader(false);
            if (resp.status) {
              setfromAmount(0);
              settoAmount(0);
              swaphistory(1);
              getUserbalance();
              showsuccessToast(resp.Message);
              navigate("/swaphistory");
            } else {
              swaphistory(1);
              showerrorToast(resp.Message);
            }
          } else {
            showerrorToast("Please enter amount");
          }
        } else {
          showerrorToast("Same currency should not allow the swapping");
        }
      } else {
        showerrorToast("Please choose the swapping currencies");
      }
    } catch (error) { }
  };

  useEffect(() => {
    getUserbalance();
    swaphistory(currentPage);
  }, [currentPage]);

  const swaphistory = async () => {
    try {
      var payload = {
        perpage: 5,
        page: currentPage,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setTotalpages(resp.data.pages);
      }
    } catch (error) { }
  };

  const handlePageChange = (data, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const swapPrice = async () => {
    setPrice(1);
  };
  const handleOnChange_from = (e, data) => {
    setAmount();
    setfromSwapRef(data.value);
    var findIndexing = fromTab.findIndex((x) => x.currid === data.value);
    if (findIndexing !== -1) {
      setappendFromData(fromTab[findIndexing]);
      setFromcurrencyImage(fromTab[findIndexing].image);
      setfromCurrency(fromTab[findIndexing].currencySymbol);
      swapPrice();
    }
  };

  const handleOnChange_to = (e, data) => {
    setAmount();
    settoSwapRef(data.value);
    var findIndexingTo = toTab.findIndex((x) => x.currid === data.value);
    if (findIndexingTo !== -1) {
      setappendFToData(toTab[findIndexingTo]);
      setTocurrencyImage(toTab[findIndexingTo].image);
      settoCurrency(toTab[findIndexingTo].currencySymbol);
      swapPrice();
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };
  const swap = () => {
    // Swap currency data
    const tempCurrency = appendFromData;
    const tempCurrencySymbol = fromCurrency;

    // Update the state with the swapped values
    setappendFromData(appendToData);
    setappendFToData(tempCurrency);
    setfromCurrency(toCurrency);
    settoCurrency(tempCurrencySymbol);

    // Swap amount data
    const tempAmount = fromAmount;
    setfromAmount(toAmount);
    settoAmount(tempAmount);

    // Update the Dropdown selected values
    setfromSwapRef(appendToData.currid);
    settoSwapRef(appendFromData.currid);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="swap_main p2p_table_content">
        <div className="container">
          <div className="">
            <p class="titlr_lable_dd text-start">
              <a href="/" class="button_home">
                <span>Home</span>
              </a>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <small>Swap</small>
            </p>
          </div>
          <div className="row">
            <div className="col-lg-5 wxdl_deposit mt-5">
              <div className="swap_pay_card">
                <div className="your_pay_text">
                  <h4>You Pay</h4>
                  <p>
                    Balance :{" "}
                    {appendFromData == ""
                      ? "0.0000"
                      : Number(appendFromData.currencyBalance).toFixed(8)}{" "}
                  </p>
                </div>
                <div className="your_pay_amt_text">
                  <h3>
                    {" "}
                    <input
                      type="number"
                      min="0"
                      placeholder="0.00"
                      value={fromAmount}
                      onChange={(e) => setAmount(e.target.value, "fromAmount")}
                      pattern="[0-9]*"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                    />{" "}
                  </h3>
                  <Dropdown
                    inline
                    placeholder="Select Coin"
                    options={fromCurrencyRef.current}
                    value={fromSwapRef.current} // Set selected value based on state
                    onChange={handleOnChange_from}
                  />
                </div>
              </div>
              <div className="swap_icon_img" onClick={swap}>
                <img
                  src={require("../assets/images/dash_icon/swap_icon.png")}
                />
              </div>
              <div className="swap_pay_card swap_pay_card2 ">
                <div className="your_pay_text">
                  <h4>You Get</h4>
                  <p>
                    Balance :{" "}
                    {appendToData == ""
                      ? "0.0000"
                      : (appendToData.p2p_type == "1") ? Number(appendToData.currencyp2pBalance).toFixed(8) : Number(appendToData.currencyBalance).toFixed(8) }{" "}
                  </p>
                </div>
                <div className="your_pay_amt_text">
                  <h3>
                    {" "}
                    <input
                      type="number"
                      value={toAmountref.current}
                      onChange={(e) => setAmount(e.target.value, "toAmount")}
                      pattern="[0-9]*"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                    />{" "}
                  </h3>
                  <Dropdown
                    inline
                    placeholder="Select Coin"
                    options={toCurrencyRef.current}
                    value={toSwapRef.current} // Set selected value based on state
                    onChange={handleOnChange_to}
                  />
                </div>
              </div>
              <div className="swap_price_text mt-5 mb-3">
                <p>Price</p>
                <p>1 USDT = 1 WXDL</p>
              </div>
              <div className="withdrawal_button">
                {ButtonLoader == false ? (
                  <button onClick={swapAmount}>
                    <span className="land-sign-letter">Swap</span>
                  </button>
                ) : (
                  <button>
                    <span className="land-sign-letter">Loading </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard_table_content mt-5">
            <div className="deposit_history_content">
              <h3 className="referral_title">Swap History</h3>
              <p>
                {" "}
                <a href="/swaphistory">View all</a>
              </p>
            </div>
            <div className="table_overflow">
              <table class="wxdl_dashboard table">
                <thead>
                  <tr>
                    <th scope="col" className="start">
                      SI.No
                    </th>
                    <th scope="col">From Currency</th>
                    <th scope="col">To Currency</th>

                    <th scope="col">Amount</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col" className="end">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sessionHistory && sessionHistory.length > 0 ? (
                    sessionHistory.map((item, i) => {
                      return (
                        <tr>
                          <th scope="row">{i + 1}</th>
                          <td className="usdt_currency_items">
                            <p>{item.fromCurrency}</p>
                          </td>



                          <td>
                            {item.toCurrency}{" "}
                          </td>

                          <td>
                            {parseFloat(item.amount).toFixed(4)}{" "}
                          </td>
                          <td>
                            {convertUTCToIST(item.createdDate)}
                          </td>

                          <td className="text-green">Success</td>
                        </tr>
                      );
                    })
                  ) : (
                    <td colSpan={6}>
                      <div className="empty_data">
                        <div className="empty_data_img">
                          {/* <img
                          src={require("../images/No-data.png")}
                          width="100px"
                        /> */}
                        </div>

                        <div className="no_records_text">No Records Found</div>
                      </div>
                    </td>
                  )}
                </tbody>
              </table>
            </div>

            {sessionHistory && sessionHistory.length > 0 ? (
              <div className="pagination">
                <Pagination
                  count={Math.ceil(totalPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  className="table_pagination"
                  shape="rounded"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Swap;
