import React, { useEffect } from "react";
import useState from "react-usestateref";
import InnerHeader from "./Header";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from 'moment-timezone';
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

export default function Deposit() {
  const navigate = useNavigate();

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("USDT");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);

  useEffect(() => {
    getAllcurrency();
    getdepositHistory();
    getTransaction();
  }, [0]);
  console.log(
    addressref.current,
    "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
  );

  const refresh = () => {
    setrefreshStatus(true);
    getTransaction();
    getdepositHistory();
  };
  const getTransaction = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    // setSiteLoader(true)

    var resp = await getMethod(data);
    setrefreshStatus(false);

    if (resp.message == true) {
      getdepositHistory();
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      console.log(allCryptoref.current, "allcrypto");
      for (var i = 0; i < data.length; i++) {
        if (data[i].depositStatus == "Active") {
          if (data[i].currencySymbol == "WXDL") {
            var obj = {
              value: data[i]._id,
              key: data[i]._id,
              text: data[i].currencySymbol,
              image: { avatar: true, src: data[i].Currency_image },
              label: data[i].currencySymbol,
              erc20token: data[i].erc20token,
              bep20token: data[i].bep20token,
              disabled: true,
              trc20token: data[i].trc20token,
              rptc20token: data[i].rptc20token,
              coinType: data[i].coinType,
              currencyName: data[i].currencyName,
              imgurl: data[i].Currency_image,
            };
          } else {
            var obj = {
              value: data[i]._id,
              key: data[i]._id,
              text: data[i].currencySymbol,
              image: { avatar: true, src: data[i].Currency_image },
              label: data[i].currencySymbol,
              erc20token: data[i].erc20token,
              bep20token: data[i].bep20token,
              trc20token: data[i].trc20token,
              rptc20token: data[i].rptc20token,
              coinType: data[i].coinType,
              currencyName: data[i].currencyName,
              imgurl: data[i].Currency_image,
            };
          }

          currArrayCrypto.push(obj);
        }
      }
      console.log("network_currencyref===", currArrayCrypto[0]);
      setallCurrency(currArrayCrypto);
      setcurrency(currArrayCrypto[0].label);
      // Setfullname(currArrayCrypto[0].currencyName);
      // setImage(currArrayCrypto[0].imgurl);
      setcointype(currArrayCrypto[0].coinType);
      // if (currArrayCrypto[0].coinType == "1") {
      //   onSelect(currArrayCrypto[0]);
      // }
    }
  };
  const [deposittotalpage, setdepositTotalpages] = useState(0);
  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const depositrecordpage = 5;

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
      setdepositTotalpages(deposit_history_list.total);
    }
  };

  const handlepagedeposit = (event, page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const onSelect = async (e, option) => {
    console.log(option, "-=-onSelecttop", e);

    setcur_network([]);
    setnet_default("");
    setcurrency(option.label);
    // Setfullname(option.currencyName);
    // setImage(option.imgurl);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
            text: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
            text: "BEP20",
            image: {
              avatar: true,
              src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
            },
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
            text: "TRC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currency: currencydata.currencySymbol,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview("view");
          setAddress(resp.Message);
          console.log(
            addressref.current,
            "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
          );
        } else {
          //showerrorToast("Something went wrong, please try again latersv");
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //showerrorToast("Something went wrong, please try again later");
        }
      }
    }
    //}
  };

  const copy = async (text,msg) => {
    navigator.clipboard.writeText(text);
    showsuccessToast(msg+" Copied");
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };
  const onSelect_network = async (e, option) => {
    setNetworks(option.value);
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
      } else {
        //showerrorToast("Something went wrong, please try again later");
      }
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="deposit_main">
        <div className="container">
          <div className="">
            <p class="titlr_lable_dd text-start">
              <a href="/" class="button_home">
                <span>Home</span>
              </a>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <small>Deposit</small>
            </p>
          </div>
          <div className="p2p_table_content">
            <div className="row">
              <div className="col-lg-5 wxdl_deposit">
                <div className="wxdl_deposit_content">
                  <h3>Currency</h3>
                  <Dropdown
                    inline
                    options={allCurrencyref.current}
                    placeholder="Select Currency"
                    defaultValue={allCurrencyref.current[0]}
                    onChange={onSelect}
                  />
                </div>
                {cur_currencyref.current.currencyType == "2" ? (
                  <div className="wxdl_deposit_content">
                    <h3>Network</h3>
                    <Dropdown
                      inline
                      placeholder="Select Currency"
                      options={network_currencyref.current}
                      defaultValue={network_currencyref.current[0]}
                      onChange={onSelect_network}
                    />
                  </div>
                ) : (
                  ""
                )}

                {addressref.current == undefined ? (
                  ""
                ) : (
                  <>
                    <div className="net_address_qr remainder_content mt-4">
                      <h3>Network Address</h3>

                      <img
                        src={
                          addressref.current == undefined
                            ? ""
                            : addressref.current.qrcode
                        }
                        className="qr_bg"
                      />

                      <p>This address only accepts deposits in USDT.</p>
                    </div>

                    <div className="currency_copy_content">
                      <input
                        type="text"
                        placeholder="1AjhrbgsdduvhgavhvsdGjhbU5BIibsb4BJ6"
                        value={
                          addressref.current == undefined
                            ? ""
                            : addressref.current.address
                        }
                      />
                      <i
                        class="ri-file-copy-line"
                        onClick={() => copy(addressref.current.address,"Address")}
                      ></i>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="dashboard_table_content mt-5">
              <div className="deposit_history_content">
                <h3 className="referral_title">Deposit History</h3>
                <p>
                  {" "}
                  <a onClick={refresh} > <i class="fa-solid fa-retweet mr-2"></i> Refresh</a>
                </p>
              </div>
              <div className="table_overflow">
                <table class="wxdl_dashboard table">
                  <thead>
                    <tr>
                      <th scope="col" className="start">
                        SI.No
                      </th>
                      <th scope="col">Currency</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col" className="end">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositHistory && depositHistory.length > 0 ? (
                      depositHistory.map((item, i) => {
                        return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td className="usdt_currency_items">
                              <img src={item.Currency_image} width="25px" />
                              <p>{item.currencySymbol}</p>
                            </td>
                            <td>
                              {parseFloat(item.amount).toFixed(4)}{" "}
                              {item.currencySymbol}{" "}
                            </td>
                            <td>
                              { convertUTCToIST(item.date)}
                            </td>
                            <td>
                              {item.txnid != undefined
                                ? item.txnid.slice(0, 10)
                                : "-"}{" "}
                              {""}{" "}
                              <i
                                class="ri-file-copy-line"
                                onClick={() => copy(item.txnid,"Transaction hash")}
                                style={{
                                  cursor: "pointer",
                                }}
                              ></i>
                            </td>
                            {item.status == "Pending" ? (
                              <td className="text-danger"> {item.status} </td>
                            ) : (
                              <td className="text-green"> {item.status} </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <td colSpan={6} className="background_trans">
                        <div className="empty_data">
                          <div className="empty_data_img">
                          </div>
                          <div className="no_records_text">No Records Found</div>
                        </div>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>

              {depositHistory && depositHistory.length > 0 ? (


                <div className="pagination">
                  <Pagination
                    count={Math.ceil(deposittotalpage / depositrecordpage)}
                    page={depositcurrentpage}
                    onChange={handlepagedeposit}
                    className="table_pagination"

                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
