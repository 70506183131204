import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../src/App.css";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-input-2/lib/style.css";
import { setAuthorization } from "../core/service/axios";

import Select from "react-select";
import countryList from "react-select-country-list";
import Header from "./Header";

export default function Register() {


    const initialFormValue = {
        email: "",
        password: "",
    };

    const navigate = useNavigate();

    const [emailValidate, setemailValidate, emailValidateref] = useState(false);
    const [passwordValidate, setpasswordValidate, passwordValidateref] =
        useState(false);
    const [validationnErr, setvalidationnErr] = useState("");
    const [formValue, setFormValue] = useState(initialFormValue);
    const [buttonLoader, setbuttonLoader] = useState(false);
    const [passHide, setPasshide] = useState(false);
    const [inputType, setinputType] = useState("password");
    const [siteLoader, setSiteLoader] = useState(false);

    const { email, password } = formValue;

    useEffect(() => {
        var token = localStorage.getItem("user_token");
        if (token) {
            navigate("/dashboard");
        }
    }, []);

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/\s/g, "");
        let formData = { ...formValue, ...{ [name]: sanitizedValue } };
        setFormValue(formData);
        validate(formData);
    };

    const validate = async (values) => {
        const errors = {};
        const username = values.email.split("@")[0];

        if (!values.email) {
            errors.email = "Email is a required field!";
            setemailValidate(true);
        } else if (
            !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address!";
            setemailValidate(true);
        } else if (username.length < 4 || username.length > 30) {
            errors.email = "Email Username must be between 4-30 characters long";
            setemailValidate(true);
        } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
            errors.email =
                " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
            setemailValidate(true);
        } else if (!/[a-zA-Z]/.test(username)) {
            errors.email = "Email username must contain at least one letter (a-z)";
            setemailValidate(true);
        } else if (values.email.length > 50) {
            errors.email = "Email address is too long!";
            setemailValidate(true);
        } else if (values.password == "") {
            setemailValidate(false);
            setpasswordValidate(true);
            errors.password = "password is required !";
        } else if (values.password.length < 5 || values.password.length > 25) {
            setpasswordValidate(true);
            errors.password = "Password should not below 5 above 25 letters !";
        } else if (!values.password.match(/[a-z]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least lower character !";
        } else if (!values.password.match(/[A-Z]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least upper character !";
        } else if (!values.password.match(/[0-9]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at One digit character !";
        } else if (!values.password.match(/[!@#$%^&*]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least one special character !";
        } else {
            setpasswordValidate(false);
        }

        setvalidationnErr(errors);
        return errors;
    };

    const passwordHide = (data) => {
        if (data == "hide") {
            setPasshide(true);
            setinputType("text");
        } else {
            setPasshide(false);
            setinputType("password");
        }
    };

    const formSubmit = async () => {
        validate(formValue);
        if (
            emailValidateref.current === false &&
            passwordValidateref.current === false
        ) {

            // if (recaptcha) {
                // console.log(formValue);
                var data = {
                    apiUrl: apiService.signin,
                    payload: formValue,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                setbuttonLoader(false);
                setFormValue(initialFormValue);
                if (resp?.tfa === 1) {
                    navigate("/verify-2fa", {
                        state: {
                            socketToken: resp?.socketToken,
                        },
                    });
                } else {
                    if (resp.status == true) {
                        setFormValue(initialFormValue);
                        toast.success(resp.Message);
                        console.log(resp, "[--=-=resp");
                        await setAuthorization(resp.token);
                        localStorage.setItem("user_token", resp.token);
                        localStorage.setItem("tfa_status", resp.tfa);
                        localStorage.setItem("socket_token", resp.socketToken);
                        localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
                        navigate("/dashboard");
                    } else {
                        setbuttonLoader(false);
                        toast.error(resp.Message);
                    }
                }
            // } else {
            //     toast.error("Recaptcha not verified")

            // }

        } else {
            console.log("Required all fields")
        }
    };
    const [recaptcha, setrecaptcha] = useState("");

    const onChange = (value) => {
        var captcha = value;
        if (captcha) {
            setrecaptcha(captcha);
        } else {
            toast.error("Captcha not verified");
        }
    };


    return (
        <div>
            <Header />
            <div className="register-one">
                <div className="container">
                    <div className="row register-form justify-content-center reg_row">
                        <div className="col-lg-5 mt-2">
                            <div className="reg_right_main">
                                <div className="reg_left_head">Welcome Back!</div>
                                <div className="p">Login with your email</div>

                                <div className="reg_in_main">
                                    <div className="reg_in_top">
                                        <div className="reg_infields">
                                            <div className="req_field_sin">
                                                <h5 className="earn_divs_insubhead">Email</h5>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    className="reg_inp_field"
                                                    placeholder="Enter your email"
                                                />
                                                {validationnErr && validationnErr.email && (
                                                    <p className="errorcss">{validationnErr.email}</p>
                                                )}
                                            </div>

                                            <div className="req_field_sin">
                                                <h5 className="earn_divs_insubhead">Password</h5>
                                                <div className="flex_input_posion">
                                                    <input
                                                        type={inputType}
                                                        name="password"
                                                        value={password}
                                                        minLength={6}
                                                        maxLength={25}
                                                        onChange={handleChange}
                                                        className="reg_inp_field"
                                                        placeholder="Enter your password"
                                                    />

                                                    {passHide == true ? (
                                                        <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHide("show")}></i>
                                                    ) : (
                                                        <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHide("hide")}></i>
                                                    )}
                                                </div>
                                                {validationnErr && validationnErr.password && (
                                                    <p className="errorcss">{validationnErr.password}</p>
                                                )}
                                            </div>

                                            {/* <div className="terms_condition_div">
                                                <ReCAPTCHA
                                                    sitekey="6Len9TAqAAAAAJXTZ7WyfrQSBhUBROenmBvkghVC"
                                                    onChange={onChange}
                                                    className="recaptcha"
                                                    theme="dark"
                                                />
                                            </div> */}

                                            <div className="req_field_sin">
                                                <h5 className="earn_divs_insubhead"> <a className="text-white" href="/forgot">
                                                    Forgot Password ?
                                                </a> </h5>

                                            </div>




                                            <div className="">


                                                <div
                                                    onClick={formSubmit}
                                                    disabled={buttonLoader}
                                                    className="template-btn btn-style-one d-flex justify-content-center mt-4"
                                                >
                                                    <span className="btn-wrap">
                                                        <span className="text-one">{buttonLoader ? "Loading....." : "Login"}</span>
                                                        <span className="text-two">{buttonLoader ? "Loading..." : "Login"}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className="newacc text-center mt-3">
                                                Don't have an account ?{" "}
                                                <Link to="/register">
                                                    <span>Register</span>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}