import React, { useEffect } from "react";
import InnerHeader from "./Header";
import useState from "react-usestateref";
import moment from 'moment-timezone';
import Pagination from "@mui/material/Pagination";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

function Staking() {

  const [buttonLoader, setbuttonLoader] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [stakeResponse, setstakeResponse] = useState("");

  const [currentAmount, setcurrentAmount] = useState("");

  const handleStakeClick = (amount, roi, packageType) => {
    // Call your function here with the passed values
    setcurrentAmount(amount);
    formSubmit(amount, roi, packageType);
  };

  const formSubmit = async (amount, roi, packageType) => {

    var obj = {
      percentageAMT: roi,
      plan: packageType,
      stakeAmount: amount,
    }

    var data = {
      apiUrl: apiService.package,
      payload: obj,
    };
    setbuttonLoader(true);
    var resp = await postMethod(data);
    

    if (resp.status == true) {

      setbuttonLoader(false);

      // toast.success(resp.Message);


      obj["userId"] = resp.userId;
      obj["username"] = resp.username;
      obj["email"] = resp.email;
      setstakeResponse(obj);

      setModalVisible(true);

      getReferralUser();


    } else {
      toast.error(resp.Message);
      setbuttonLoader(false);
    }


  };




  const [referral, setreferral] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 5;

  
  useEffect(() => {
    getReferralUser();
  }, [currentPage]);

  const getReferralUser = async () => {

    var datas = {
      apiUrl: apiService.getstaking,
      payload: {
        currentPage: currentPage, // ReactPaginate uses 0-based index, so we add 1
        pageSize: pageSize,
      },
    };
    var response = await postMethod(datas);
    if (response.status) {
      setreferral(response.data);
      setTotalPages(response.totalPages);
    } else {
      setreferral([]);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClose = () => {
    setModalVisible(false);
  };


  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };




  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="staking_main">
        <section className="staking_banner_section">
          <div className="container">
            <div className="staking_banner_content">
              <h1>
                Start Earning rewards with &nbsp;
                <br />
                WXDLPRO Staking.
              </h1>
              <button className="start_now_button" >Start Earning Now</button>
            </div>
          </div>
        </section>

        <div className="container">
          <section className="essential_premium_section">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Essential
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Premium
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <div className="essential_package_main">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="package-1">
                        <h3>Starter Package</h3>
                        <h2>
                          50<span>WXDL</span>
                        </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button" onClick={() => handleStakeClick(50, 0.4, "Starter")}  >
                          Stake WXDL
                        </button>
                        ) : (
                         currentAmount == 50 ?
                         (
                          <button className="starter_stake_button" >
                          Loading
                         </button>
                         )
                         : (
                          <button className="starter_stake_button" onClick={() => handleStakeClick(50, 0.4, "Starter")}  >
                          Stake WXDL
                        </button>
                         )
                        )}
                        
                        <p>
                          <i class="ri-check-line"></i> Daily ROI 0.4%
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Profit Cap 3X
                        </p>

                        <p>
                          <i class="ri-check-line"></i> Fees - 2 WXDL
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="package-1">
                        <h3>Basic Package</h3>
                        <h2>
                          100<span>WXDL</span>
                        </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(100, 0.4, "Basic")}  >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 100 ?
                          (
                           <button className="starter_stake_button" >
                           Loading
                          </button>
                          )
                          : (
                        <button className="starter_stake_button" onClick={() => handleStakeClick(100, 0.4, "Basic")}  >
                          Stake WXDL
                        </button>
                          )
                        )}
                        <p>
                          <i class="ri-check-line"></i> Daily ROI 0.4%
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Profit Cap 3X
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Fees - 2 WXDL
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="package-1">
                        <h3>Advanced Package</h3>
                        <h2>
                          200<span>WXDL</span>
                        </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(200, 0.4, "Advanced")}  >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 200 ?
                         (
                          <button className="starter_stake_button" >
                          Loading
                         </button>
                         )
                         : (
                          <button className="starter_stake_button" onClick={() => handleStakeClick(200, 0.4, "Advanced")}  >
                          Stake WXDL
                        </button>
                         )
                        )}
                        <p>
                          <i class="ri-check-line"></i> Daily ROI 0.4%
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Profit Cap 3X
                        </p>

                        <p>
                          <i class="ri-check-line"></i> Fees - 2 WXDL
                        </p>
                      </div>
                    </div>
                    <div className="staking_tabs_bg">
                      <img
                        src={require("../assets/images/dash_icon/staking_tabs_bg.png")}
                      />
                    </div>
                    <div className="pro_package_list">
                      <div className="col-lg-4">
                        <div className="package-1">
                          <h3>Pro Package</h3>
                          <h2>
                            300<span>WXDL</span>
                          </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(300, 0.4, "Pro")}  >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 300 ?
                          (
                           <button className="starter_stake_button" >
                           Loading
                          </button>
                          )
                          : (
                            <button className="starter_stake_button" onClick={() => handleStakeClick(300, 0.4, "Pro")}  >
                          Stake WXDL
                        </button>
                          )
                        )}
                          <p>
                            <i class="ri-check-line"></i> Daily ROI 0.4%
                          </p>
                          <p>
                            <i class="ri-check-line"></i> Profit Cap 3X
                          </p>

                          <p>
                            <i class="ri-check-line"></i> Fees - 2 WXDL
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="package-1">
                          <h3>Elite Package</h3>
                          <h2>
                            500<span>WXDL</span>
                          </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(500, 0.4, "Elite")}  >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 500 ?
                          (
                           <button className="starter_stake_button" >
                           Loading
                          </button>
                          )
                          : (
                            <button className="starter_stake_button" onClick={() => handleStakeClick(500, 0.4, "Elite")}  >
                          Stake WXDL
                        </button>
                          )
                        )}
                          <p>
                            <i class="ri-check-line"></i> Daily ROI 0.4%
                          </p>
                          <p>
                            <i class="ri-check-line"></i> Profit Cap 3X
                          </p>

                          <p>
                            <i class="ri-check-line"></i> Fees - 2 WXDL
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                <div className="essential_package_main premium">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="package-1">
                        <h3>Bronze Package</h3>
                        <h2>
                          1000<span>WXDL</span>
                        </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(1000, 0.5, "Bronze")} >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 1000 ?
                          (
                           <button className="starter_stake_button" >
                           Loading
                          </button>
                          )
                          : (
                            <button className="starter_stake_button" onClick={() => handleStakeClick(1000, 0.4, "Bronze")}  >
                          Stake WXDL
                        </button>
                          )
                        )}
                        <p>
                          <i class="ri-check-line"></i> Daily ROI 0.5%
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Profit Cap 3X
                        </p>

                        <p>
                          <i class="ri-check-line"></i> Fees - 2 WXDL
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="package-1">
                        <h3>Silver Package</h3>
                        <h2>
                          2000<span>WXDL</span>
                        </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(2000, 0.5, "Silver")} >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 2000 ?
                          (
                           <button className="starter_stake_button" >
                           Loading
                          </button>
                          )
                          : (
                            <button className="starter_stake_button" onClick={() => handleStakeClick(2000, 0.4, "Silver")}  >
                          Stake WXDL
                        </button>
                          )
                        )}
                        <p>
                          <i class="ri-check-line"></i> Daily ROI 0.5%
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Profit Cap 3X
                        </p>

                        <p>
                          <i class="ri-check-line"></i> Fees - 2 WXDL
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="package-1">
                        <h3>Gold Package</h3>
                        <h2>
                          5000<span>WXDL</span>
                        </h2>
                        {!buttonLoader ? (
                          <button className="starter_stake_button"  onClick={() => handleStakeClick(5000, 0.5, "Gold")} >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 5000 ?
                         (
                          <button className="starter_stake_button" >
                          Loading
                         </button>
                         )
                         : (
                          <button className="starter_stake_button" onClick={() => handleStakeClick(5000, 0.4, "Gold")}  >
                          Stake WXDL
                        </button>
                         )
                        )}
                        <p>
                          <i class="ri-check-line"></i> Daily ROI 0.5%
                        </p>
                        <p>
                          <i class="ri-check-line"></i> Profit Cap 3X
                        </p>

                        <p>
                          <i class="ri-check-line"></i> Fees - 2 WXDL
                        </p>
                      </div>
                    </div>
                    <div className="staking_tabs_bg">
                      <img
                        src={require("../assets/images/dash_icon/staking_tabs_bg.png")}
                      />
                    </div>
                    <div className="pro_package_list">
                      <div className="col-lg-4">
                        <div className="package-1">
                          <h3>Platinum Package</h3>
                          <h2>
                            8000<span>WXDL</span>
                          </h2>
                          {!buttonLoader ? (
                          <button className="starter_stake_button" onClick={() => handleStakeClick(8000, 0.5, "Platinum")} >
                          Stake WXDL
                        </button>
                        ) : (
                          currentAmount == 8000 ?
                          (
                           <button className="starter_stake_button" >
                           Loading
                          </button>
                          )
                          : (
                            <button className="starter_stake_button" onClick={() => handleStakeClick(8000, 0.4, "Platinum")}  >
                          Stake WXDL
                        </button>
                          )
                        )}
                          <p>
                            <i class="ri-check-line"></i> Daily ROI 0.5%
                          </p>
                          <p>
                            <i class="ri-check-line"></i> Profit Cap 3X
                          </p>

                          <p>
                            <i class="ri-check-line"></i> Fees - 2 WXDL
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="package-1">
                          <h3>Diamond Package</h3>
                          <h2>
                            10000<span>WXDL</span>
                          </h2>
                          {!buttonLoader ? (
                          <button className="starter_stake_button" onClick={() => handleStakeClick(10000, 0.5, "Diamond")} >
                          Stake WXDL
                          </button>
                          ) : (
                            currentAmount == 10000 ?
                            (
                             <button className="starter_stake_button" >
                             Loading
                            </button>
                            )
                            : (
                              <button className="starter_stake_button" onClick={() => handleStakeClick(10000, 0.5, "Diamond")} >
                              Stake WXDL
                              </button>
                            )
                          )}
                          
                          <p>
                            <i class="ri-check-line"></i> Daily ROI 0.5%
                          </p>
                          <p>
                            <i class="ri-check-line"></i> Profit Cap 3X
                          </p>

                          <p>
                            <i class="ri-check-line"></i> Fees - 2 WXDL
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="container mt-5">
          <div className="dashboard_table_content">
            <h3 className="referral_title">Staking History</h3>
            <div className="table_overflow">
              <table class="wxdl_dashboard table">
                <thead>
                  <tr>
                    <th scope="col" className="start">
                      SI.No
                    </th>
                    <th scope="col">Plan</th>
                    <th scope="col">Stake Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="end">
                      Date & Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {referral && referral.length > 0 ? (
                    referral.map((item, i) => {
                      var dates = convertUTCToIST(item.Data);

                      return (
                        <tr>
                          <td>{i + 1} </td>
                          <td>{item.Plan}</td>
                          <td>{item.StakeAmount} {" "} WXDL</td>
                          <td>{item.status}</td>
                          {/* <td>{item.status}</td> */}
                          <td>{dates}</td>


                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4"> No Data found! </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                count={totalPages}
                shape="rounded"
                className="table_pagination"
                onChange={handlePageChange}
              />


             <Modal isOpen={modalVisible} className="modalss" toggle={() => setModalVisible(false)}>
              <ModalBody>
                <div className="modal-header kyc_modal_header">
                  <img
                    src={require('../assets/images/dash_icon/tick.webp')}
                    className="d-block mx-auto"
                    width="100px"
                    alt="Success"
                  />
                  <h6>
                    Staking successfully done. <br />
                    Activated Plan <span>{stakeResponse.plan}</span> <br />
                    Plan Amount <span> {stakeResponse.stakeAmount}$</span> <br />
                    Name <span> {stakeResponse.username} </span> <br />
                    {/* Email <span> {stakeResponse.email} </span> <br /> */}
                    UserId <span> {stakeResponse.userId} </span> <br />
                  </h6>
                  <h4>
                    <button onClick={handleClose}>
                      Close
                    </button>
                  </h4>
                </div>
              </ModalBody>
            </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Staking;
