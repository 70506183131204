import React, { useEffect } from "react";
import InnerHeader from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

const Settings = () => {
  const navigate = useNavigate();

  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const initialFormValue1 = { password1: "", confirmPassword1: "" };
  const initialFormValue2 = { old: "", password2: "", confirmPassword2: "" };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [formValue2, setFormValue2] = useState(initialFormValue2);
  const { oldpassword, password, confirmPassword } = formValue;
  const { password1, confirmPassword1 } = formValue1;
  const { old, password2, confirmPassword2 } = formValue2;
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [passwordValidate1, setpasswordValidate1, passwordValidate1ref] =
    useState(false);
  const [
    confirmPasswordValidate1,
    setconfirmPasswordValidate1,
    confirmPasswordValidate1ref,
  ] = useState(false);

  const [passwordValidate2, setpasswordValidate2, passwordValidate2ref] =
    useState(false);

  const [oldvalidation, setoldvalidation, oldvalidationref] = useState(false);

  const [
    confirmPasswordValidate2,
    setconfirmPasswordValidate2,
    confirmPasswordValidate2ref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] =
    useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHide1, setpassHide1] = useState(false);
  const [inputType1, setinputType1] = useState("password");
  const [passHidconf1, setPasshideconf1] = useState(false);
  const [inputTypeconf1, setinputTypeconf1] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [passHide2, setpassHide2] = useState(false);
  const [inputType2, setinputType2] = useState("password");
  const [passHidconf2, setPasshideconf2] = useState(false);
  const [inputTypeconf2, setinputTypeconf2] = useState("password");
  const [passHidnew2, setPasshidenew2] = useState(false);
  const [inputTypenew2, setinputTypenew2] = useState("password");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [validationnErr1, setvalidationnErr1] = useState("");
  const [validationnErr2, setvalidationnErr2] = useState("");
  const [settingstatus, setsettingstatus] = useState(0);
  const [UserDetails, setUserDetails] = useState({});
  const [email, setemail] = useState({});




  const getProfile = async () => {
    try {
      var datas = {
        apiUrl: apiService.getuserdetail,
      };
      var responce = await getMethod(datas);
      console.log(responce);
      if (responce) {
        setUserDetails(responce.data);
        console.log(responce.data.username, "icikneknedkneknew")
      }
    } catch (error) {
      console.log(error, "error");
      // toast.success("Please try  later!");
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (values.oldpassword === "") {
      errors.oldpassword = "Old Password is required";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password === "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (values.password.length < 8 || values.password.length > 45) {
      setpasswordValidate(true);
      errors.password = "New Password should be between 8 and 45 characters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one lowercase letter";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one uppercase letter";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one digit";
    } else if (values.password === values.oldpassword) {
      errors.password = "Old Password and New password should not be the same";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword === "") {
      errors.confirmPassword = "Confirm Password is required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword !== values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and Confirm Password do not match";
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors = {};

    if (values.password1 === "") {
      setpasswordValidate1(true);
      errors.password1 = "Password is required";
    } else if (values.password1.length < 8 || values.password1.length > 45) {
      setpasswordValidate1(true);
      errors.password1 = "New Password should be between 8 and 45 characters";
    } else if (!values.password1.match(/[a-z]/g)) {
      setpasswordValidate1(true);
      errors.password1 = "Please enter at least one lowercase letter";
    } else if (!values.password1.match(/[A-Z]/g)) {
      setpasswordValidate1(true);
      errors.password1 = "Please enter at least one uppercase letter";
    } else if (!values.password1.match(/[0-9]/g)) {
      setpasswordValidate1(true);
      errors.password1 = "Please enter at least one digit";
    } else {
      setpasswordValidate1(false);
    }

    if (values.confirmPassword1 === "") {
      errors.confirmPassword1 = "Confirm Password is required";
      setconfirmPasswordValidate1(true);
    } else if (values.confirmPassword1 !== values.password1) {
      setconfirmPasswordValidate1(true);
      errors.confirmPassword1 = "Password and Confirm Password do not match";
    } else {
      setconfirmPasswordValidate1(false);
    }
    setvalidationnErr1(errors);
    return errors;
  };

  const validate2 = async (values) => {
    const errors = {};
    if (values.old === "") {
      errors.old = "Old Password is required";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password2 === "") {
      setpasswordValidate2(true);
      errors.password2 = "Password is required";
    } else if (values.password2.length < 8 || values.password2.length > 45) {
      setpasswordValidate2(true);
      errors.password2 = "New Password should be between 8 and 45 characters";
    } else if (!values.password2.match(/[a-z]/g)) {
      setpasswordValidate2(true);
      errors.password2 = "Please enter at least one lowercase letter";
    } else if (!values.password2.match(/[A-Z]/g)) {
      setpasswordValidate2(true);
      errors.password2 = "Please enter at least one uppercase letter";
    } else if (!values.password2.match(/[0-9]/g)) {
      setpasswordValidate2(true);
      errors.password2 = "Please enter at least one digit";
    } else if (values.password2 === values.oldpassword) {
      errors.password2 = "Old Password and New password should not be the same";
      setpasswordValidate2(true);
    } else {
      setpasswordValidate2(false);
    }

    if (values.confirmPassword2 === "") {
      errors.confirmPassword2 = "Confirm Password is required";
      setconfirmPasswordValidate2(true);
    } else if (values.confirmPassword2 !== values.password2) {
      setconfirmPasswordValidate2(true);
      errors.confirmPassword2 = "Password and Confirm Password do not match";
    } else {
      setconfirmPasswordValidate2(false);
    }
    setvalidationnErr2(errors);
    return errors;
  };

  const [buttonStatus, setButtonstatus] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);
  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");

    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
    if (
      !confirmPasswordValidateref.current &&
      !passwordValidateref.current &&
      !oldpassvalidateref.current
    ) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  useEffect(() => {
    Settingdata();
    getProfile();
  }, []);

  const handleSubmit = async () => {
    validate(formValue);
    console.log(formValue, "formValue");
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      setSiteLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      setSiteLoader(false);
      if (resp.status == true) {
        showsuccessToast(resp.Message);
      } else {
        showerrorToast(resp.Message);
      }
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let data = { oldpassword: formValue.oldpassword, newpassword: formValue.password };
  //   if (!confirmPasswordValidateref.current && !passwordValidateref.current && !oldpassvalidateref.current) {
  //     setbuttonLoader(true);
  //     try {
  //       let url = apiService.changePassword;
  //       const response = await postMethod({ url, data });
  //       if (response.status) {
  //         showsuccessToast(response.Message);
  //         setbuttonLoader(false);
  //         setFormValue({ ...formValue, ...initialFormValue });
  //       } else {
  //         setbuttonLoader(false);
  //         showerrorToast(response.Message);
  //       }
  //     } catch (error) {
  //       setbuttonLoader(false);
  //     }
  //   }
  // };

  const handleChange1 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");

    let formData = { ...formValue1, ...{ [name]: sanitizedValue } };
    setFormValue1(formData);
    validate1(formData);
    if (!confirmPasswordValidate1ref.current && !passwordValidate1ref.current) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  // const handleSubmit1 = async (e) => {
  //   e.preventDefault();
  //   let data = { transactionpassword: formValue1.password1 };
  //   if (!confirmPasswordValidate1ref.current && !passwordValidate1ref.current) {
  //     setbuttonLoader(true);
  //     try {
  //       let url = apiService.createTransactionPassword;
  //       const response = await postMethod({ url, data });
  //       if (response.status) {
  //         showsuccessToast(response.Message);
  //         setbuttonLoader(false);
  //         setFormValue1({ ...formValue1, ...initialFormValue1 });
  //       } else {
  //         setbuttonLoader(false);
  //         showerrorToast(response.Message);
  //       }
  //     } catch (error) {
  //       setbuttonLoader(false);
  //     }
  //   }
  // };

  const handleSubmit1 = async () => {
    validate1(formValue1);

    if (
      passwordValidate1ref.current == false &&
      confirmPasswordValidate1ref.current == false
    ) {
      var obj = {
        password: formValue1.password1,
        confirmPassword: formValue1.confirmPassword1,
      };

      var data = {
        apiUrl: apiService.transactionpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      setSiteLoader(true);
      var resp = await postMethod(data);

      console.log(resp, "resp");
      setbuttonLoader(false);
      setSiteLoader(false);

      if (resp.status == true) {
        showsuccessToast(resp.Message);
        Settingdata();
      } else {
        showerrorToast(resp.Message);
      }
    }
  };

  const handleChange2 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue2, ...{ [name]: sanitizedValue } };
    setFormValue2(formData);
    validate2(formData);
    if (
      !confirmPasswordValidate2ref.current &&
      !passwordValidate2ref.current &&
      !oldvalidationref.current
    ) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  const handleSubmit2 = async () => {
    validate2(formValue2);

    if (
      confirmPasswordValidate2ref.current == false &&
      passwordValidate2ref.current == false &&
      oldvalidationref.current == false
    ) {
      var obj = {
        oldPass: formValue2.old,
        password: formValue2.password2,
        cpass: formValue2.confirmPassword2,
      };

      var data = {
        apiUrl: apiService.changetransactionPassword,
        payload: obj,
      };
      setbuttonLoader(true);
      setSiteLoader(true);
      var resp = await postMethod(data);

      console.log(resp, "resp");
      setbuttonLoader(false);
      setSiteLoader(false);

      if (resp.status == true) {
        showsuccessToast(resp.Message);
        Settingdata();
      } else {
        showerrorToast(resp.Message);
      }
    }
  };

  const Settingdata = async () => {
    var data = {
      apiUrl: apiService.settingdata,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    console.log(resp, "resp");
    if (resp.status == true) {
      // showsuccessToast(resp.Message);
      setsettingstatus(resp.data.Transactionpassstatus);
      console.log(settingstatus, "settingstatus");
      setemail(resp.data.email);
    } else {
      showerrorToast(resp.Message);
    }
  };

  const forgot = async () => {
    var data = {
      apiUrl: apiService.transactionOTP,
    };
    setSiteLoader(true);
    var resp = await postMethod(data);
    setSiteLoader(false);

    console.log(resp, "resp");
    if (resp.status == true) {
      showsuccessToast(resp.Message);
      navigate("/forgotpassword");
    } else {
      showerrorToast(resp.Message);
    }
  };
  const handlePassHide = () => {
    if (!passHide) {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const handlePassHideconf = () => {
    if (!passHidconf) {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const handlePassHide1 = () => {
    if (!passHide1) {
      setpassHide1(true);
      setinputType1("text");
    } else {
      setpassHide1(false);
      setinputType1("password");
    }
  };

  const handlePassHideconf1 = () => {
    if (!passHidconf1) {
      setPasshideconf1(true);
      setinputTypeconf1("text");
    } else {
      setPasshideconf1(false);
      setinputTypeconf1("password");
    }
  };

  const handlePassHide2 = () => {
    if (!passHide2) {
      setpassHide2(true);
      setinputType2("text");
    } else {
      setpassHide2(false);
      setinputType2("password");
    }
  };

  const handlePassHideconf2 = () => {
    if (!passHidconf2) {
      setPasshideconf2(true);
      setinputTypeconf2("text");
    } else {
      setPasshideconf2(false);
      setinputTypeconf2("password");
    }
  };

  const handlePassHideNew2 = () => {
    if (!passHidnew2) {
      setPasshidenew2(true);
      setinputTypenew2("text");
    } else {
      setPasshidenew2(false);
      setinputTypenew2("password");
    }
  };

  const handlePassHideNew = () => {
    if (!passHidnew) {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  const [loaderButton, setloaderButton] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoadertfa, setsiteLoadertfa] = useState(false);

  useEffect(() => {
    fetchTfaData();
  }, []);

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      setsiteLoadertfa(true);
      var resp = await getMethod(data);
      setsiteLoadertfa(false);
      setTfaDetails(resp.data.tfastatus);
      setaddDetails(resp.data);
    } catch (error) { }
  };

  const handletfa = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      showerrorToast("2FA code is required");
    } else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        showsuccessToast(resp.Message);
        setTfaCode("");
        fetchTfaData();
        localStorage.setItem("tfa_status", resp.result.tfastatus);
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
        showerrorToast(resp.Message);
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Secret Key Copied");
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <div className="settings_main">
        <div className="container padding_inner">
          {/* <div className="">
            <p class="titlr_lable_dd text-start">
              <a href="/" class="button_home">
                <span>Home</span>
              </a>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <small>Settings</small>
            </p>
          </div> */}
          <section className="setting_tab_section ">
            <nav className="">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Change Password
                </button>

                <button
                  class="nav-link"
                  id="Two-FA-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Two-FA"
                  type="button"
                  role="tab"
                  aria-controls="Two-FA"
                  aria-selected="true"
                >
                  2FA
                </button>

                <button
                  class="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  {settingstatus == 0
                    ? "Transaction Password"
                    : "Change Transaction Password"}
                </button>

                <button
                  class="nav-link"
                  id="user-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#user-profile"
                  type="button"
                  role="tab"
                  aria-controls="user-profile"
                  aria-selected="true"
                >
                  Profile
                </button>
              </div>
            </nav>
            <div className="row">
              <div className="col-lg-5 settings_password_tabs mt-5">

                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                    tabindex="0"
                  >
                    <div className="change_password_content ">
                      <div className="req_field_sin">
                        <h3>Old Password</h3>

                        <div className="flex_input_posion">
                          <input
                            type={inputTypenew}
                            name="oldpassword"
                            value={formValue.oldpassword}
                            onChange={handleChange}
                            placeholder="Old Password"
                            className="reg_inp_field"
                          />

                          <span
                            className="pass-hide-icon"
                            onClick={handlePassHideNew}
                          >
                            {passHidnew ? (
                              <i class="fa-regular icons fa-eye reg_eye" />
                            ) : (
                              <i class="fa-regular icons fa-eye-slash reg_eye" />
                            )}
                          </span>
                        </div>
                        {oldpassvalidateref.current && (
                          <span className="errorcss">
                            {validationnErr.oldpassword}
                          </span>
                        )}
                      </div>

                      <div>
                        <h3>New Password</h3>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={formValue.password}
                            onChange={handleChange}
                            placeholder="New Password"
                            className="reg_inp_field"
                          />

                          <span
                            className="pass-hide-icon"
                            onClick={handlePassHide}
                          >
                            {passHide ? (
                              <i class="fa-regular icons fa-eye reg_eye" />
                            ) : (
                              <i class="fa-regular icons fa-eye-slash reg_eye" />
                            )}
                          </span>
                        </div>

                        {passwordValidateref.current && (
                          <span className="errorcss">
                            {validationnErr.password}
                          </span>
                        )}
                      </div>

                      <div>
                        <h3>Confirm Password</h3>

                        <div className="flex_input_posion">
                          <input
                            className="reg_inp_field"
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={formValue.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                          />
                          <span
                            className="pass-hide-icon"
                            onClick={handlePassHideconf}
                          >
                            {passHidconf ? (
                              <i class="fa-regular icons fa-eye reg_eye" />
                            ) : (
                              <i class="fa-regular icons fa-eye-slash reg_eye" />
                            )}
                          </span>
                        </div>
                        {confirmPasswordValidateref.current && (
                          <span className="errorcss">
                            {validationnErr.confirmPassword}
                          </span>
                        )}
                      </div>
                      {buttonLoader == false ? (
                        <button
                          className="password_sumbit_button"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      ) : (
                        <button className="password_sumbit_button">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="user-profile"
                    role="tabpanel"
                    aria-labelledby="user-profile-tab"
                    tabindex="0"
                  >
                    <div className="change_password_content reg_right_main mt-2">
                      <div className="reg_infields">
                        

                        <div className="req_field_sin">
                          <h5 className="earn_divs_insubhead">User Name</h5>
                          <input
                            className="reg_inp_field"
                            readOnly
                            placeholder="Enter Your Code"
                            value={UserDetails?.username}
                            
                          />
                        </div>

                        <div className="req_field_sin">
                          <h5 className="earn_divs_insubhead">User Email</h5>
                          <input
                            className="reg_inp_field"
                            readOnly
                            placeholder="Enter Your Code"
                            value={email}
                            
                          />
                        </div>

                        <div className="req_field_sin">
                          <h5 className="earn_divs_insubhead">Mobile Number</h5>
                          <input
                            className="reg_inp_field"
                            type="number"
                            min="0"
                            readOnly
                            placeholder="Enter Your Code"
                            value={UserDetails?.mobileNumber}
                            
                          />
                        </div>

                        <div className="req_field_sin">
                          <h5 className="earn_divs_insubhead">Sponsor ID</h5>
                          <input
                            className="reg_inp_field"
                            readOnly
                            value={UserDetails?.referral}
                            
                          />
                        </div>

                      
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="Two-FA"
                    role="tabpanel"
                    aria-labelledby="Two-FA-tab"
                    tabindex="0"
                  >
                    <div className="change_password_content reg_right_main mt-2">
                      <div className="reg_infields">
                        {tfaDetails === 0 ? (
                          <>
                            <img src={addDetails.tfa_url} className="qrcodes" />

                            <div className="req_field_sin">
                              <h5 className="earn_divs_insubhead">
                                Secret Key
                              </h5>
                              <div className="flex_input_posion">
                                <input
                                  type="text"
                                  value={
                                    addDetails.tfaenablekey == undefined
                                      ? ""
                                      : addDetails.tfaenablekey
                                  }
                                  readOnly
                                  className="reg_inp_field"
                                />

                                <i
                                  class="fa-regular fa-copy search-icon text-white t25"
                                  id="Eye"
                                  onClick={() => copy(addDetails.tfaenablekey)}
                                ></i>
                              </div>
                            </div>
                          </>
                        ) : (
                          " "
                        )}

                        <div className="req_field_sin">
                          <h5 className="earn_divs_insubhead">2FA Code</h5>
                          <input
                            className="reg_inp_field"
                            type="number"
                            min="0"
                            placeholder="Enter Your Code"
                            name="tfaCode"
                            value={tfaCode}
                            onChange={(e) => setTfaCode(e.target.value)}
                          />
                        </div>

                        {/* <div className="">
                          <div className="template-btn btn-style-one d-flex justify-content-center mt-4"> */}
                            {tfaDetails === 0 ? (
                              loaderButton == false ? (
                                <div className="" onClick={handletfa}>
                               <div className="template-btn btn-style-one d-flex justify-content-center mt-4">
                                <span className="btn-wrap" >
                                  <span className="text-one">Enable</span>
                                  <span className="text-two">Enable</span>
                                </span>
                                </div>
                                </div>
                              ) : (
                                <div className="">
                               <div className="template-btn btn-style-one d-flex justify-content-center mt-4">
                                <span className="btn-wrap">
                                  <span className="text-one">Loading</span>
                                  <span className="text-two">Loading</span>
                                </span>
                                </div>
                                </div>
                              )
                            ) : loaderButton == false ? (
                              <div className="" onClick={handletfa}>
                               <div className="template-btn btn-style-one d-flex justify-content-center mt-4">
                              <span className="btn-wrap" >
                                <span className="text-one">Disable</span>
                                <span className="text-two">Disable</span>
                              </span>
                              </div>
                              </div>
                            ) : (
                              <div className="">
                               <div className="template-btn btn-style-one d-flex justify-content-center mt-4">
                              <span className="btn-wrap">
                                <span className="text-one">Loading</span>
                                <span className="text-two">Loading</span>
                              </span>
                              </div>
                              </div>
                            )}
                          {/* </div>
                        </div> */}

                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                    tabindex="0"
                  >
                    {settingstatus == 0 ? (
                      <>
                        <div className="change_password_content">
                          <div>
                            <h3>New Password</h3>
                            <div className="flex_input_posion">
                              <input
                                type={inputType1}
                                name="password1"
                                value={formValue1.password1}
                                onChange={handleChange1}
                                className="reg_inp_field"
                                placeholder="Enter Your Password"
                              />

                              <span
                                className="pass-hide-icon"
                                onClick={handlePassHide1}
                              >
                                {passHide1 ? (
                                  <i class="fa-regular icons fa-eye reg_eye" />
                                ) : (
                                  <i class="fa-regular icons fa-eye-slash reg_eye" />
                                )}
                              </span>
                            </div>

                            {passwordValidate1ref.current && (
                              <span className="errorcss">
                                {validationnErr1.password1}
                              </span>
                            )}
                          </div>

                          <div>
                            <h3>Confirm Password</h3>

                            <div className="flex_input_posion">
                              <input
                                className="reg_inp_field"
                                type={inputTypeconf1}
                                name="confirmPassword1"
                                value={formValue1.confirmPassword1}
                                onChange={handleChange1}
                                placeholder="Confirm Password"
                              />
                              <span
                                className="pass-hide-icon"
                                onClick={handlePassHideconf1}
                              >
                                {passHidconf1 ? (
                                  <i class="fa-regular icons fa-eye reg_eye" />
                                ) : (
                                  <i class="fa-regular icons fa-eye-slash reg_eye" />
                                )}
                              </span>
                            </div>
                            {confirmPasswordValidate1ref.current && (
                              <span className="errorcss">
                                {validationnErr1.confirmPassword1}
                              </span>
                            )}
                          </div>
                          <button
                            className="password_sumbit_button"
                            onClick={handleSubmit1}
                          >
                            Submit
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="change_password_content">
                        <div className="req_field_sin">
                          <h3>Old Password</h3>

                          <div className="flex_input_posion">
                            <input
                              type={inputTypenew2}
                              className="reg_inp_field"
                              name="old"
                              value={formValue2.old}
                              onChange={handleChange2}
                              placeholder="Old Password"
                            />

                            {/* {passHide2 == true ? (
                              <i
                                class="fa-regular icons fa-eye reg_eye"
                                onClick={() => passwordHide("show")}
                              ></i>
                            ) : (
                              <i
                                class="fa-regular icons fa-eye-slash reg_eye"
                                onClick={() => passwordHide("hide")}
                              ></i>
                            )} */}

                            <span
                              className="pass-hide-icon"
                              onClick={handlePassHideNew2}
                            >
                              {passHidnew2 ? (
                                <i class="fa-regular icons fa-eye reg_eye" />
                              ) : (
                                <i class="fa-regular icons fa-eye-slash reg_eye" />
                              )}
                            </span>
                          </div>
                          {passwordValidate2ref.current && (
                            <span className="errorcss">
                              {validationnErr2.old}
                            </span>
                          )}
                        </div>

                        <div>
                          <h3>New Password</h3>
                          <div className="flex_input_posion">
                            <input
                              type={inputType2}
                              className="reg_inp_field"
                              name="password2"
                              value={formValue2.password2}
                              onChange={handleChange2}
                              placeholder="New Password"
                            />

                            {/* {passHidnew2 == true ? (
                              <i
                                class="fa-regular icons fa-eye reg_eye"
                                onClick={() => passwordHidenewP("show")}
                              ></i>
                            ) : (
                              <i
                                class="fa-regular icons fa-eye-slash reg_eye"
                                onClick={() => passwordHidenewP("hide")}
                              ></i>
                            )} */}

                            <span
                              className="pass-hide-icon"
                              onClick={handlePassHide2}
                            >
                              {passHide2 ? (
                                <i class="fa-regular icons fa-eye reg_eye" />
                              ) : (
                                <i class="fa-regular icons fa-eye-slash reg_eye" />
                              )}
                            </span>
                            {oldpassvalidateref.current && (
                              <span className="errorcss">
                                {validationnErr2.password2}
                              </span>
                            )}
                          </div>

                          {/* {passwordValidate2ref.current && (
                            <span className="errorcss">
                              {validationnErr2.password2}
                            </span>
                          )} */}
                        </div>

                        <div>
                          <h3>Confirm Password</h3>

                          <div className="flex_input_posion">
                            <input
                              type={inputTypeconf2}
                              className="reg_inp_field"
                              name="confirmPassword2"
                              value={formValue2.confirmPassword2}
                              onChange={handleChange2}
                              placeholder="Confirm Password"
                            />
                            <span
                              className="pass-hide-icon"
                              onClick={handlePassHideconf2}
                            >
                              {passHidconf2 ? (
                                <i class="fa-regular icons fa-eye reg_eye" />
                              ) : (
                                <i class="fa-regular icons fa-eye-slash reg_eye" />
                              )}
                            </span>
                          </div>
                          {confirmPasswordValidate2ref.current && (
                            <span className="errorcss">
                              {validationnErr2.confirmPassword2}
                            </span>
                          )}
                        </div>

                        <button
                          className="password_sumbit_button"
                          onClick={handleSubmit2}
                        >
                          Submit
                        </button>
                        <a onClick={forgot} className="forgot_password_text">
                          {" "}
                          Forgot Password ?
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Settings;
